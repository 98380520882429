// Home.js
import { connect } from 'react-redux';

import withRouter from '../component/WithRouter.jsx';

import {
  LIVESTREAM_FEED,
  SEARCH_SWAGGER_ONLINE_FEED,
  LEADERBOARD_IDS,
  SHOWCASE_FEED,
  TRENDING_HASHTAG_FEED,
  TRENDING_CATEGORY_FEED,
  DEFAULT_OG_IMAGE_URL,
  DEFAULT_OG_TITLE,
  DEFAULT_OG_DESCRIPTION,
  PAGINATION_ITEM_COUNT_PER_PAGE,
} from '../RemoteConfigKeys.js';
import Home from '../page/Home.jsx';
import getMeData from '../selector/getMeData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getModalData from '../selector/getModalData.js';
import fetchRemoteConfigList from '../action/fetchRemoteConfigList.js';
import fetchFeeds, { getReturnType } from '../action/fetchFeeds.js';
import setShouldPlayFeedId from '../action/setShouldPlayFeedId.js';
import { SEARCH_MODAL_ID } from '../resource/searchConstants.js';
import { getHomePageConfig } from '../resource/homeUtils.js';

const mapStateToProps = (state, { location: { pathname } }) => {
  const searchSwaggerOnlineFeed = getRemoteConfigData(
    state,
    SEARCH_SWAGGER_ONLINE_FEED
  );
  const livestreamFeedName = getRemoteConfigData(state, LIVESTREAM_FEED);
  const leaderboardIds = getRemoteConfigData(state, LEADERBOARD_IDS);
  const { categoriesRemoteConfigKey, tabsComponent, isCategoryWidthLimited } =
    getHomePageConfig({
      pathname,
    });
  const remoteConfigCategories = getRemoteConfigData(
    state,
    categoriesRemoteConfigKey
  );
  const paginationItemCountPerPage = getRemoteConfigData(
    state,
    PAGINATION_ITEM_COUNT_PER_PAGE
  );
  const [firstFeed] =
    remoteConfigCategories.filter(
      category => !category.startsWith('pseudo_')
    ) || [];
  const returnType = getReturnType({ feedName: firstFeed });
  const isFirstFeedFetched = getNetworkingData(
    state,
    ['home', returnType, firstFeed, 1],
    'isFetched'
  );
  const isPaginatedFirstFeedFetched = getNetworkingData(
    state,
    ['home', returnType, firstFeed, 1, paginationItemCountPerPage],
    'isFetched'
  );
  const isSearchModalOpened = ['opening', 'display'].includes(
    getModalData(state, SEARCH_MODAL_ID, 'status')
  );
  const shouldRenderSearchBar = !isSearchModalOpened;
  const trendingHashtagFeed = getRemoteConfigData(state, TRENDING_HASHTAG_FEED);
  const trendingCategoryFeed = getRemoteConfigData(
    state,
    TRENDING_CATEGORY_FEED
  );
  return {
    // use token instead of userId to reduce list api call
    isAuthed: !!getMeData(state, 'token'),
    pathname,
    tabsComponent,
    remoteConfigCategories,
    livestreamFeedName,
    jumboVideoFeedName: getRemoteConfigData(state, SHOWCASE_FEED),
    trendingHashtagFeed,
    trendingCategoryFeed,
    leaderboardIds,
    searchSwaggerOnlineFeed,
    isFirstFeedFetched: isFirstFeedFetched || isPaginatedFirstFeedFetched,
    isCategoryWidthLimited,
    configListError: getNetworkingData(state, ['config', 'configure'], 'error'),
    shouldRenderSearchBar,
    defaultOgImageUrl: getRemoteConfigData(state, DEFAULT_OG_IMAGE_URL),
    defaultOgTitle: getRemoteConfigData(state, DEFAULT_OG_TITLE),
    defaultOgDescription: getRemoteConfigData(state, DEFAULT_OG_DESCRIPTION),
    paginationItemCountPerPage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setShouldPlayFeedId: ({ feedId }) =>
      dispatch(setShouldPlayFeedId({ feedId })),
    fetchRemoteConfigList: ({ httpProxyHeaders }) =>
      dispatch(fetchRemoteConfigList({ httpProxyHeaders })),
    fetchFeeds: payload => dispatch(fetchFeeds(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
