// ImagePreloader.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import getPublicSrcSet from '../resource/getPublicSrcSet.js';
import { EMPTY_ARRAY } from '../resource/defaults.js';
import { MediaAssetFormat } from '../resource/getMediaAsset.js';

export const ImagePreloader = ({
  preloadUrls = EMPTY_ARRAY,
  preloadSize = 3,
  preloadImageSize = 512,
  preloadImageFormat = MediaAssetFormat.AVIF,
}) => {
  if (!preloadUrls.length) return null;

  return (
    <Helmet>
      {preloadUrls.slice(0, preloadSize).map(url => {
        const srcSet = getPublicSrcSet({
          href: url,
          size: preloadImageSize,
          format: preloadImageFormat,
        });

        return (
          <link
            key={url}
            rel="preload"
            fetchPriority="high"
            as="image"
            href={srcSet.src}
            type={`image/${preloadImageFormat}`}
          />
        );
      })}
    </Helmet>
  );
};

ImagePreloader.propTypes = {
  preloadUrls: PropTypes.array,
  preloadSize: PropTypes.number,
  preloadImageSize: PropTypes.number,
  preloadImageFormat: PropTypes.string,
};

export default ImagePreloader;
