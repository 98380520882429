// ImagePreloader.js
'use strict';
import { connect } from 'react-redux';

import ImagePreloader from '../component/ImagePreloader.jsx';
import getPaginationData from '../selector/getPaginationData.js';
import getListData from '../selector/getListData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getMessageThumbnail from '../selector/getMessageThumbnail.js';
import { PAGINATION_ITEM_COUNT_PER_PAGE } from '../RemoteConfigKeys.js';
import { getFeedNameType } from '../resource/feedUtils.js';

const mapStateToProps = (
  state,
  { category, isInPaginationMode, preloadImageSize, preloadImageFormat }
) => {
  const selectPath = [
    'home',
    getFeedNameType({ feedName: category }),
    category,
  ];
  const limit = getRemoteConfigData(state, PAGINATION_ITEM_COUNT_PER_PAGE);
  const currentPage = 1;

  const messageIds =
    (isInPaginationMode
      ? getPaginationData(
          state,
          selectPath,
          'itemIds',
          undefined,
          limit,
          currentPage
        )
      : getListData(state, selectPath, 'itemIds')) || [];

  const preloadUrls = messageIds.map(messageId =>
    getMessageThumbnail(
      state,
      messageId,
      '',
      `${preloadImageSize}x${preloadImageSize}`,
      preloadImageFormat,
      'clear'
    )
  );

  return {
    preloadUrls,
  };
};

export default connect(mapStateToProps)(ImagePreloader);
